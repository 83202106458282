<template>
  <div id="app">
    <flash-message class="myCustomClass"></flash-message>
    <div class="row">
      <div class="col col-md-12">
        <div class="row">
          <br />
          <br />
        </div>
        <div class="row">
          <div class="col col-md-12">

            <!--<div class="row">
              <div class="col col-md-12">
                <select v-model="camera" class="form-control">
                  <option>&#45;&#45; Selecione o dispositivo &#45;&#45;</option>
                  <option
                          v-for="device in devices"
                          :key="device.deviceId"
                          :value="device.deviceId"
                  >{{ device.label }}</option>
                </select>
              </div>
            </div>-->
          </div>
        </div>
        <div class="row margin-top-20">
          <div class="col col-md-12">
            <div class="row">
              <div class="col col-md-12">
                <web-cam
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        :height="250"
                        @started="onStarted"
                        @stopped="onStopped"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-4 col-xs-offset-4" style="text-align: right;">
                <Center>
                  <img src="../public/camera.png" @click="onCapture"/>
                </Center>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <div class="row ">
              <div class="col col-md-12 container-documents">
                <hr />
                <div class="row margin-top-10" v-bind:key="key" v-for="(imagem, key) in documents">
                  <div class="col col-sm-12">
                    <img :src="imagem.conteudo" style="width:100%;" v-if="imagem.conteudo"/>
                    <a :href="imagem.url" target="_blank" v-if="imagem.url">{{imagem.url}}</a>
                    <br />
                    <br />
                    <span style="font-size:30px;" v-if="!isPrestador && (usercanviewiconexcludedocuments == true)" class="margin-top-10" v-on:click="removeFile(imagem.urlToRemove, key)">
                      <i class="glyphicon glyphicon-trash"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import { WebCam } from "vue-web-cam";
import axios from "axios";
import VueFlashMessage from 'vue-flash-message';

Vue.use(VueFlashMessage);

export default {
  name: 'app',
  props: ['usertoken','atividadeexecucaotoken','urlserver','application','isPrestador','usercanviewiconexcludedocuments'],
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      documents: []
    }
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const devices = this.devices;

      if(devices != null){
        const first = this.devices[this.devices.length - 1];

        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {
    log: function (e) {
      console.log(e);
    },
    removeFile(urlToRemove, keyToRemove){

      axios.post(`${urlToRemove}`)
              .then(function(success){
                var dados = success.data;

                console.log(dados);
                try{
                  if(dados.status === 'OK'){
                    this.$delete(this.documents, keyToRemove);
                  }
                  else{
                    this.flash(dados.status_mensagem, 'error', {
                      timeout: 3000
                    });
                  }
                }
                catch(err){
                  this.flash(err, 'error', {
                    timeout: 3000
                  });
                }

              }.bind(this))
              .catch(err  => {
                this.flash(err, 'error', {
                  timeout: 3000
                });
              });
    },
    onCapture() {
      this.img = this.$refs.webcam.capture();
      this.uploadImage(this.img,this.usertoken,this.atividadeexecucaotoken);
    },
    onStarted() {
      //console.log("On Started Event", stream);
    },
    onStopped() {
      //console.log("On Stopped Event", stream);
    },
    onStop() {
      //this.$refs.webcam.stop();
    },
    onStart() {
      //this.$refs.webcam.start();
    },
    onError() {
      //console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      //console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //console.log("On Camera Change Event", deviceId);
    },
    uploadImage(image,userToken,atividadeExecucaoToken){

      if(image && userToken && atividadeExecucaoToken){

        const params = new URLSearchParams();
        params.append('uploadImage', image);
        params.append('userToken', userToken);

        axios.post(`${this.urlserver}/panel/upload?atividadeToken=${atividadeExecucaoToken}`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
                .then(function(success){
                  var dados = success.data;

                  try{
                    if(dados.status === 'OK'){
                      this.flash('Imagem Capturada', 'success', {
                        timeout: 3000
                      });
                      this.documents.push(dados);
                    }
                    else{
                      this.flash(dados.status_mensagem, 'error', {
                        timeout: 3000
                      });
                    }
                  }
                  catch(err){
                    this.flash(err, 'error', {
                      timeout: 3000
                    });
                  }
                }.bind(this))
                .catch(err  => {
                  this.flash(err, 'error', {
                    timeout: 3000
                  });
                });
      }
    },
    getDocumentosAtividadeExecucao(atividadeExecucaoToken){

      if(atividadeExecucaoToken){

        axios.get(`${this.urlserver}/panel/get-documentos-atividade-execucao?tokenAtividadeExecucao=${atividadeExecucaoToken}`)
                .then(function(success){
                  var dados = success.data;

                  try{
                    if(dados != null){
                      this.documents = dados;
                    }
                  }
                  catch(err){
                    this.flash(err, 'error', {
                      timeout: 3000
                    });
                  }
                }.bind(this))
                .catch(err  => {
                  this.flash(err, 'error', {
                    timeout: 3000
                  });
                });
      }
    },
  },
  components: {
    WebCam
  },
  mounted() {
    this.documents = this.getDocumentosAtividadeExecucao(this.atividadeexecucaotoken);
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
