import Vue from 'vue'
//import App from './App.vue'

Vue.config.productionTip = false;

/*new Vue({
  render: h => h(App),
}).$mount('#app')*/

Vue.component('app', require('./App.vue').default);

window.Vue = Vue;
